import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const MyTheme = createMuiTheme({
    palette: {
        secondary: {
            light: '#2574a9',
            main: '#376079',
            dark: '#2c3e50',
            contrastText: '#fff',
        },
        primary: {
            light: '#97c9ec',
            main: '#6bb9f0',
            dark: '#2c6084',
            contrastText: '#000',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    overrides: {
        RaLayout: {
            root: {
                backgroundColor: '#f3f3f3',
            },
            contentWithSidebar: {
                minHeight: '100vh',
                maxWidth: '100vw',
                overflowX: 'hidden',
            },
            content: {
                ['@media (min-width:0px)']: {
                    paddingLeft: '24px',
                },
                overflowX: 'hidden',
                paddingRight: '0'
            }
        },
        RaAppBar: {
            toolbar: {
                background: 'linear-gradient(90deg, rgba(44,62,80,1) 0%, rgba(37,116,169,1) 100%)',
            },
        },
        MuiDrawer: {
            docked: {
                backgroundColor: '#292929',
            }
        },
        RaSidebar: {
            drawerPaper: {
                ['@media (max-width: 599.95px) and (min-width: 0px)']: {
                    backgroundColor: '#292929',
                },
                backgroundColor: '#292929',
            }
        },
        RaMenuItemLink: {
            root: {
                color: '#e4e4e4',
            },
            active: {
                color: '#6bb9f0',
            }
        },
        MuiListItemIcon: {
            root: {
                color: '#fff'
            },
            active: {
                color: '#6bb9f0',
            }
        },
        MuiTableCell: {
            root: {
                whiteSpace: 'nowrap',
            }
        },
        MuiDivider: {
            light: {
                backgroundColor: 'rgba(255,255,255, 0.25)'
            }
        },
        RaList: {
            main: {
                overflowX: 'scroll',
            }
        },
        RaListToolbar: {
            toolbar: {
                paddingRight: '24px',
            }
        }
    }
});
