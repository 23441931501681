import React from "react";
import {
    DateField,
    NumberField,
    TextField,
    NumberInput,
    AutocompleteInput,
    DateInput,
    ReferenceField,
    ReferenceManyField,
    Edit,
    SelectField,
    Pagination,
    TextInput,
    ReferenceInput,
    sanitizeListRestProps,
    useListContext,
    CreateButton,
    SelectInput,
    TopToolbar,
    Create
} from '../../node_modules/react-admin';

import { cloneElement } from 'react';
import FieldSet from "../views/FieldSet";
import FieldsView from "../views/FieldsView";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import SubpanelsView from "../views/SubpanelsView";
import Subpanel from "../views/Subpanel";
import {DonationStatus, PaymentMethods, ProjectStages} from "../Enums";
import CustomList from '../Components/CustomList';
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import SimpleColumnForm from "../views/SimpleColumnForm";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomLabeled from "../Components/CustomLabeled";

const ProjectsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => ([
            <Box component={"span"}>Offener Betrag: </Box>,
            <NumberField addLabel={true} record={record} source={"openAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
        ])}
        tertiaryText={record => (<NumberField addLabel={true} record={record} source={"cost"} options={{ style: 'currency', currency: 'EUR' }}/>)}
    />
};

const ProjectsListFilters = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <TextInput label="search.quick" source="name" alwaysOn variant="outlined"/>
    </SchemaBasedFiltersInspector>
);

const DonationsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (
            <ReferenceField basePath={"donors"} record={record} source={"donor"} reference={"donors"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        secondaryText={record => ([
            <NumberField record={record} source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField choices={DonationStatus} record={record} source={"status"}/>
        ])}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
}

const ProjectsListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    );
};

export const ProjectsList = props => (
    <CustomList {...props}
        smallList={<ProjectsSimpleList/>}
        filters={<ProjectsListFilters variant="standard"/>}
        actions={<ProjectsListActions/>}
        sort={{ field: (props.sortBy ?? 'dateBooked'), order: 'DESC' }}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <TextField source="type" />
            <TextField source={"country"}/>
            <NumberField source="numOfProjects"/>
            <SelectField source="stage" choices={ProjectStages}/>
            <DateField source={"lastUpdate"} showTime={false}/>
            <NumberField source="totalAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="cost" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="netAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="openAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="fee" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="ownAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <DateField source="dateBooked" />
            <ReferenceField source="userAssigned" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

export const ProjectsShow = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField source={"name"}/>
                <TextField source="type"/>

                <TextField source={"country"}/>
                <NumberField source={"people"}/>

                <NumberField source={"numOfProjects"}/>
                <Box/>

                <SelectField source="stage" choices={ProjectStages}/>
                <DateField source={"lastUpdate"} showTime={false}/>

                <NumberField source="cost" options={{style: 'currency', currency: 'EUR'}}/>
                <DateField source="dateBooked"/>

                <NumberField source="openAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <NumberField source="totalAmount" options={{style: 'currency', currency: 'EUR'}}/>

                <NumberField source="netAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <NumberField source="fee" options={{style: 'currency', currency: 'EUR'}}/>

                <NumberField source="ownAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <Box/>

                <NumberField source="latitude"/>
                <NumberField source="longitude"/>

                <TextField source={"description"} span={12} multiline={true}/>

                <ReferenceField source={"userAssigned"} reference={"users"}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source={"externalId"}/>
                <TextField source={"internalKey"}/>
                <Box/>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
            </FieldsView>
            <SubpanelsView>
                <Subpanel label={"resources.donations.pluralName"}>
                    <ReferenceManyField sort={{field: 'dateBooked', order: 'DESC'}} pagination={<Pagination/>}
                                        perPage={5} reference="donations" target="project" addLabel={false}>
                        {isSmall ? (
                            <DonationsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <NumberField source={"totalAmount"} options={{style: 'currency', currency: 'EUR'}}/>
                                <ReferenceField source="donor" reference="donors">
                                    <TextField source="name"/>
                                </ReferenceField>
                                <SelectField source={"status"} choices={DonationStatus}/>
                                <SelectField source={"paymentMethod"} choices={PaymentMethods}/>
                                <DateField source={"dateBooked"} showTime={true}/>
                                <ReferenceField source={"campaign"} reference={"campaigns"}>
                                    <TextField source="name"/>
                                </ReferenceField>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
            </SubpanelsView>
        </SimpleColumnLayout>
    </CustomShow>
};

export const ProjectsEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput source="name" />
            <TextInput source="type" />

            <TextInput source={"country"}/>
            <NumberInput source={"people"}/>

            <NumberInput source={"numOfProjects"}/>
            <Box/>

            <SelectInput source={"stage"} choices={ProjectStages}/>
            <DateInput disabled source={"lastUpdate"}/>

            <NumberInput source="cost"/>
            <DateInput source="dateBooked" />

            <NumberInput disabled source="openAmount"/>
            <NumberInput disabled source="totalAmount"/>

            <NumberInput disabled source="netAmount"/>
            <NumberInput disabled source="fee"/>

            <NumberInput source="ownAmount"/>
            <Box/>

            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>

            <TextInput multiline={true} source={"description"} span={12}/>

            <ReferenceInput source={"userAssigned"} reference={"users"} filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <TextInput disabled source={"externalId"} />

            <TextInput source={"internalKey"}/>
            <Box/>

        </SimpleColumnForm>
    </Edit>
);

export const ProjectsCreate = props => (
    <Create {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput source="name" />
            <TextInput source="type" />

            <TextInput source={"country"}/>
            <NumberInput source={"people"}/>

            <NumberInput source={"numOfProjects"}/>
            <Box/>

            <SelectInput source="stage" choices={ProjectStages}/>
            <DateInput disabled source={"lastUpdate"}/>

            <NumberInput source="cost"/>
            <DateInput source="dateBooked" />

            <NumberInput source="ownAmount"/>
            <Box/>

            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>

            <TextInput multiline={true} source={"description"} span={12}/>

            <ReferenceInput source={"userAssigned"} reference={"users"} filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <TextInput source={"internalKey"}/>

        </SimpleColumnForm>
    </Create>
);
