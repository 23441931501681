import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { useTranslate } from 'react-admin';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ListIcon from '@material-ui/icons/List';

export const CustomMenu = ({ onMenuClick, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            <MenuItemLink
                to="/dashboards/customer-care"
                primaryText="CustomerCare"
                leftIcon={<ChildCareIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <Divider variant="middle" light={true}/>
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) || translate('resources.' + resource.name + '.pluralName') ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <Divider variant="middle" light={true}/>
            <MenuItemLink
                to="/emailTemplates"
                primaryText={translate('menu.emailTemplates')}
                leftIcon={<MailOutlineIcon />}
                onClick={event => window.open('https://emailtemplates.ynspirewater.org', '_blank')}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to={"/donationLists"}
                primaryText={translate('menu.donorList')}
                leftIcon={<ListIcon/>}
                onClick={event => window.open('https://docs.google.com/spreadsheets/d/1ZstAl6PbBglCJBSDOzgPwH-HPSSqMbhVcQfWsb2gHXA/edit#gid=0')}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to={"/projectLists"}
                primaryText={translate('menu.projectList')}
                leftIcon={<ListIcon/>}
                onClick={event => window.open('https://docs.google.com/spreadsheets/d/17Zs4ATvbNl16qToQHKJLtDZvMfIbNiYcIKtydFQSprE/edit#gid=0')}
                sidebarIsOpen={open}
            />
            {isXSmall && [<Divider variant="middle" light={true}/>,logout]}
        </div>
    );
};
