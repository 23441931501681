import React from 'react';
import PropTypes from 'prop-types';
import { FormWithRedirect } from 'ra-core';
import SimpleColumnFormView from "./SimpleColumnFormView";

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

var SimpleColumnForm = function (props) {
    return (
        React.createElement(
            FormWithRedirect,
            __assign({}, props, {
                render: function (formProps) {
                    return React.createElement(SimpleColumnFormView, __assign({}, formProps));
                }
            })
        )
    );
};
SimpleColumnForm.propTypes = {
    children: PropTypes.node,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    save: PropTypes.func,
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    undoable: PropTypes.bool,
    validate: PropTypes.func,
    version: PropTypes.number,
};

export default SimpleColumnForm;
