import React from 'react';
import PropTypes from 'prop-types';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import {
    Button,
    fetchStart,
    fetchEnd,
    showNotification,
    useDataProvider,
    useRefresh
} from '../../node_modules/react-admin';

const defaultIcon = React.createElement(AlternateEmail, null);

const WriteEmailButton = function (_a) {
    const label = _a.label === void 0 ? 'action.writeEmail' : _a.label;
    const record = _a.record;
    const _d = _a.icon;
    const icon = _d === void 0 ? defaultIcon : _d;
    const refresh = useRefresh();

    const dataProvider = useDataProvider();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const userEmailAddress = currentUser && currentUser.emailAddress;

    const writeEmail = function (e) {
        fetchStart();
        console.log(record);

        const newDonorData = {
            id: record.id,
            data: {
                userAssigned: '/api/users/' + currentUser.id
            }
        };

        dataProvider.update('donors', newDonorData)
            .then(({data}) => {
                return dataProvider.getOne('emailAddresses', {id: data.emailAddresses[0]});
            })
            .then(({data}) => {
                window.open("https://mail.google.com/mail/u/" + userEmailAddress + "/?view=cm&fs=1&tf=1&to=" + data.name, '_blank');
            })
            .then(() => {
                fetchEnd();
                refresh();
            })
            .catch(error => {
                showNotification(error.message, "error");
            });

        return e.stopPropagation();
    };

    return (
        (record && record.emailAddresses && record.emailAddresses.length) ? React.createElement(Button,
            {
                label: label,
                onClick: writeEmail
            },
            icon
        ) : null
    );
};


WriteEmailButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default WriteEmailButton;
