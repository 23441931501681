import React from "react";
import {
    DateField,
    TextField,
    ReferenceField,
    Labeled,
} from '../../node_modules/react-admin';

import { Box } from '@material-ui/core';
import FieldSet from "../views/FieldSet";
import FieldsView from "../views/FieldsView";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import CustomShow from "../Components/CustomShow";
import CustomList from "../Components/CustomList";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomSimpleList from "../Components/CustomSimpleList";
import CustomLabeled from "../Components/CustomLabeled";

export const EmailsShow = (props) => {
    return <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField source="name"/>
                <ReferenceField source={"donor"} reference={"donors"}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField multiline span={12} source="content"/>
                <ReferenceField source={"userAssigned"} reference={"users"}>
                    <TextField source="name"/>
                </ReferenceField>
                <Box/>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
            </FieldsView>
        </SimpleColumnLayout>
    </CustomShow>
};

const EmailsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => <TextField record={record} source={"templateName"}/>}
        tertiaryText={record => (<DateField showTime={true} record={record} source={"dateCreated"}/>)}
    />
};

export const EmailsList = props => (
    <CustomList {...props}
                smallList={<EmailsSimpleList/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <TextField source={"templateName"}/>
            <ReferenceField source="donor" reference="donors" link={"show"}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateCreated" showTime={true} />
            <TextField source="causedBy"/>
        </CustomDatagrid>
    </CustomList>
);
