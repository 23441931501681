import React, { Component } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { withTranslate } from 'react-admin';
import {
    Box,
    Paper
} from '@material-ui/core'

import './LoginView.css';
import LocaleSwitcher from "../Components/LocaleSwitcher.js"

class LoginView extends Component {
    render() {
        const { translate } = this.props;

        var responseSuccessGoogle = (response) => {
            console.log('success: ', response);
            this.props.userLogin({
                token: response.credential
            });
        };

        var responseFailureGoogle = (response) => {
            console.log('error: ', response);
        };

        return (
            <Box className={'login-background'}>
                <Box position={"relative"} top={"15vh"} display={"flex"}>
                    <Box mx={"auto"} width={350} textAlign={"center"}>
                        <Paper>
                            <Box p={4}>
                                <Box p={2} pb={4}>
                                    <img style={{maxWidth: '100%'}} src="/ynspire_water_website_140px.png"/>
                                </Box>
                                <GoogleLogin
                                    buttonText={translate('login.google')}
                                    onSuccess={responseSuccessGoogle}
                                    onError={responseFailureGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </Box>
                            <LocaleSwitcher/>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withTranslate(connect(undefined, { userLogin })(LoginView));
