import React from "react";
import {
    DateField,
    NumberField,
    TextField,
    ReferenceArrayField,
    EmailField,
    ReferenceField,
    SingleFieldList,
    ReferenceManyField,
    ReferenceArrayInput,
    Edit,
    AutocompleteArrayInput,
    EditButton,
    SelectField,
    Pagination,
    Labeled,
    TopToolbar,
    TextInput,
    NumberInput,
    ReferenceInput,
    BooleanInput,
    BooleanField,
    SelectInput,
} from '../../node_modules/react-admin';

import { useMediaQuery } from '@material-ui/core';
import SimpleColumnForm from '../views/SimpleColumnForm'
import CustomList from '../Components/CustomList'

import {
    Languages,
    DonationStatus,
    DonorSource,
    DonorCategories,
    SubscriptionStatus,
    PaymentMethods,
    PaymentProviders, SubscriptionPeriods
} from '../Enums'
import { Box } from '@material-ui/core';
import SimpleColumnLayout from '../views/SimpleColumnLayout';
import FieldSet from '../views/FieldSet';
import Subpanel from '../views/Subpanel';
import FieldsView from '../views/FieldsView';
import SubpanelsView from '../views/SubpanelsView';
import ShowDonationSummaryRecipeButton from "../Components/ShowDonationSummaryRecipeButton";
import SchemaBasedFiltersInspector from '../views/SchemaBasedFilters'
import WriteEmailButton from "../Components/WriteEmailButton";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import CustomLabeled from "../Components/CustomLabeled";

const DonorsListFilters = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <TextInput label="search.quick" source="name" alwaysOn variant="outlined"/>
        <ReferenceInput source="userAssigned" reference="users" allowEmpty filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source={"preferredLanguage"} choices={Languages}/>
        <SelectInput source={"category"} choices={DonorCategories}/>
        <SelectInput source={"source"} choices={DonorSource}/>
    </SchemaBasedFiltersInspector>
);

const DonorsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => [<Box component={"span"}>Stadt: </Box>, <TextField record={record} source={"addressLocality"}/>]}
        tertiaryText={record => (<SelectField record={record} choices={Languages} source={"preferredLanguage"}/>)}
    />
};

const DonationsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => [
            <NumberField record={record} source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField record={record} source={"paymentMethod"} choices={PaymentMethods}/>
        ]}
        secondaryText={record => <SelectField choices={DonationStatus} record={record} source={"status"}/>}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
};

const CampaignsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => <TextField record={record} source={"name"}/>}
        secondaryText={record => [
            <Box>Gesammelt: </Box>,
            <NumberField record={record} source={"raisedAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
        ]}
        tertiaryText={record => (<DateField record={record} source={"dateStart"}/>)}
    />
};

const SubscriptionsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (
            [<NumberField record={record} source={"monthlyAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField choices={PaymentMethods} record={record} source={"paymentMethod"}/>]
        )}
        secondaryText={record => <SelectField choices={SubscriptionStatus} record={record} source={"status"}/>}
        tertiaryText={record => (<DateField record={record} source={"dateStart"}/>)}
    />
};

const EmailsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"} />)}
        secondaryText={record => (<DateField record={record} source={"dateCreated"}/>)}
    />
};

const ExternalIdentifiersSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"provider"} />)}
        secondaryText={record => (<TextField record={record} source={"name"}/>)}
    />
};

export const DonorsList = props => (
    <CustomList {...props}
          filters={<DonorsListFilters variant="standard"/>}
          smallList={<DonorsSimpleList/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <ReferenceArrayField source="emailAddresses" reference='email_addresses'>
                <SingleFieldList>
                    <EmailField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <SelectField source={"preferredLanguage"} choices={Languages} />
            <NumberField source="donatedAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="raisedAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <ReferenceField source="userAssigned" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

const DonorsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ShowDonationSummaryRecipeButton basePath={basePath} record={data}/>
        <WriteEmailButton record={data}/>
    </TopToolbar>
);

export const DonorsShow = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <CustomShow {...props} actions={<DonorsShowActions/>}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField source={"givenName"} />
                <TextField source={"familyName"} />
                <SelectField source={"category"} choices={DonorCategories} />
                <SelectField source={"source"} choices={DonorSource} />
                <NumberField source={"donatedAmount"} options={{ style: 'currency', currency: 'EUR' }} />
                <NumberField source={"raisedAmount"} options={{ style: 'currency', currency: 'EUR' }} />
                <SelectField source={"preferredLanguage"} choices={Languages} />
                <ReferenceArrayField source="emailAddresses" reference='email_addresses'>
                    <SingleFieldList linkType={null}>
                        <EmailField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <FieldSet>
                    <CustomLabeled label={"resources.donors.fields.addressFieldSet"} margin={""}/>
                    <TextField source={"streetAddress"} addLabel={false} />
                    <TextField source={"addressLocality"} addLabel={false} />
                    <TextField source={"postalCode"} addLabel={false} />
                    <TextField source={"addressRegion"} addLabel={false} />
                    <TextField source={"addressCountry"} addLabel={false} />
                </FieldSet>
                <ReferenceField source={"userAssigned"} reference={"users"} addLabel={true}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source={"description"} span={12}/>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </FieldSet>
                <TextField source={"externalId"} />
                <BooleanField source={"isFromTeam"} />
            </FieldsView>
            <SubpanelsView>
                <Subpanel label={"resources.donations.pluralName"}>
                    <ReferenceManyField sort={{ field: 'dateBooked', order: 'DESC' }} pagination={<Pagination/>} perPage={5} reference="donations" target="donor" addLabel={false}>
                        {isSmall ? (
                            <DonationsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <NumberField source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }} />
                                <SelectField source={"status"} choices={DonationStatus} />
                                <SelectField source={"paymentProvider"} choices={PaymentProviders} />
                                <SelectField source={"paymentMethod"} choices={PaymentMethods} />
                                <DateField source={"dateBooked"} showTime={true}/>
                                <ReferenceField source={"campaign"} reference={"campaigns"}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source={"project"} reference={"projects"}>
                                    <TextField source="name" />
                                </ReferenceField>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
                <Subpanel label={"resources.campaigns.pluralName"}>
                    <ReferenceManyField pagination={<Pagination/>} perPage={5} reference="campaigns" target="fundraiser" addLabel={false}>
                        {isSmall ? (
                            <CampaignsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <TextField source={"name"}/>
                                <TextField source="type" />
                                <TextField source="url" />
                                <NumberField source={"raisedAmount"} options={{ style: 'currency', currency: 'EUR' }} />
                                <NumberField source="numDonations" />
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
                <Subpanel label={"resources.subscriptions.pluralName"}>
                    <ReferenceManyField pagination={<Pagination/>} perPage={5} reference="subscriptions" target="donor" addLabel={false}>
                        {isSmall ? (
                            <SubscriptionsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <NumberField source="monthlyAmount" options={{ style: 'currency', currency: 'EUR' }} />
                                <SelectField source="period" choices={SubscriptionPeriods} />
                                <SelectField source={"status"} choices={SubscriptionStatus} />
                                <SelectField source={"paymentMethod"} choices={PaymentMethods} />
                                <DateField source="dateStart"/>
                                <NumberField source="totalAmount" options={{ style: 'currency', currency: 'EUR' }} />
                                <DateField source="dateModified" showTime={true} />
                                <DateField source="dateCreated" showTime={true} />
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
                <Subpanel label={"resources.emails.pluralName"}>
                    <ReferenceManyField pagination={<Pagination/>} perPage={5} reference="emails" target="donor" addLabel={false}>
                        {isSmall ? (
                            <EmailsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <TextField source="name"/>
                                <TextField source="templateName"/>
                                <DateField source="dateCreated" showTime={true}/>
                                <TextField source="causedBy"/>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
                <Subpanel label={"resources.external_identifiers.pluralName"}>
                    <ReferenceManyField pagination={<Pagination/>} perPage={5} reference="external_identifiers" target="donor" addLabel={false}>
                        {isSmall ? (
                            <ExternalIdentifiersSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <TextField source="name"/>
                                <TextField source="provider"/>
                                <DateField source="dateCreated" showTime={true}/>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
            </SubpanelsView>
        </SimpleColumnLayout>
    </CustomShow>
};

export const DonorsEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput source={"givenName"} />
            <TextInput source={"familyName"} />
            <SelectInput source={"category"} choices={DonorCategories} />
            <SelectInput source={"source"} choices={DonorSource} />
            <NumberInput disabled source={"donatedAmount"}/>
            <NumberInput disabled source={"raisedAmount"}/>
            <SelectInput source={"preferredLanguage"} choices={Languages} />
            <ReferenceArrayInput source="emailAddresses" reference='email_addresses' perPage={5} filterToQuery={searchText => ({ name: [searchText] })}>
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
            <FieldSet>
                <TextInput source={"streetAddress"}/>
                <TextInput source={"addressLocality"}/>
                <TextInput source={"postalCode"}/>
                <TextInput source={"addressRegion"}/>
                <TextInput source={"addressCountry"}/>
            </FieldSet>
            <ReferenceInput source={"userAssigned"} reference={"users"}  filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput multiline source={"description"} span={12}/>
            <TextInput disabled source={"externalId"} />
            <BooleanInput source={"isFromTeam"} />
        </SimpleColumnForm>
    </Edit>
);
