import React from "react";
import {
    DateField,
    NumberField,
    TextField,
    BooleanField,
    ReferenceField,
    ReferenceManyField,
    ReferenceInput,
    AutocompleteInput,
    Edit,
    TextInput,
    SelectInput,
    BooleanInput,
    SelectField,
    Pagination,
} from '../../node_modules/react-admin';

import {
    Languages, SubscriptionStatus,
} from '../Enums'

import SimpleColumnLayout from "../views/SimpleColumnLayout";
import FieldsView from "../views/FieldsView";
import SubpanelsView from "../views/SubpanelsView";
import Subpanel from "../views/Subpanel";
import SimpleColumnForm from "../views/SimpleColumnForm";
import CustomList from '../Components/CustomList'
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";

const UsersListFilter = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <TextInput label="search.quick" source="name" alwaysOn/>
    </SchemaBasedFiltersInspector>
);

const UsersSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField source={"name"} record={record}/>)}
        />
};

export const UsersList = props => (
    <CustomList {...props}
        filters={<UsersListFilter variant={"standard"}/>}
        smallList={<UsersSimpleList/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <TextField source={"email"} />
            <TextField source={"username"} />
            <BooleanField source={"fromWordpress"} />
            <SelectField source={"preferredLanguage"} choices={Languages} />
            <DateField source={"dateCreated"}/>
        </CustomDatagrid>
    </CustomList>
);

export const UsersShow = props => (
    <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField source={"givenName"}/>
                <TextField source={"familyName"}/>
                <TextField source={"email"}/>
                <TextField source={"username"}/>
                <BooleanField source={"fromWordpress"}/>
                <SelectField source={"preferredLanguage"} choices={Languages} />
                <ReferenceField source="donor" reference="donors">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source={"description"}/>
                <TextField source={"externalId"}/>
            </FieldsView>
            <SubpanelsView>
                <Subpanel label={"resources.campaigns.pluralName"}>
                    <ReferenceManyField sort={{ field: 'dateStart', order: 'DESC' }} pagination={<Pagination/>} perPage={5} reference="campaigns" target="fundraiser" addLabel={false}>
                        <CustomDatagrid>
                            <TextField source={"name"}/>
                            <NumberField source="raisedAmount" options={{ style: 'currency', currency: 'EUR' }} />
                            <NumberField source="numDonations"/>
                            <DateField source="dateStart" showTime={true}/>
                            <DateField source="dateEnd" showTime={true}/>
                            <TextField source="url" />
                            <DateField source="dateModified" showTime={true} />
                            <DateField source="dateCreated" showTime={true} />
                        </CustomDatagrid>
                    </ReferenceManyField>
                </Subpanel>
            </SubpanelsView>
        </SimpleColumnLayout>
    </CustomShow>
);

export const UsersEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput source={"givenName"}/>
            <TextInput source={"familyName"}/>
            <TextInput type={"email"} source={"email"}/>
            <TextInput disabled source={"username"}/>
            <BooleanInput disabled source={"fromWordpress"}/>
            <SelectInput source={"preferredLanguage"} choices={Languages} />
            <ReferenceInput disabled source="donor" reference="donors" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <TextInput multiline span={12} source={"description"}/>
            <TextInput disabled source={"externalId"}/>
        </SimpleColumnForm>
    </Edit>
);
