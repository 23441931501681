import React from "react";
import {
    Show,
} from '../../node_modules/react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import DialogProvider from "./AlertDialogProvider";

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{record ? translate('resources.' + (record['@type'].toLowerCase() + 's') + '.name') + ` - ${record.name}` : ''}</span>;
};

const CustomShow = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles({
        root: {
            '& > div:last-child > div': {
                maxWidth: isSmall ? '100%' : 'calc(100% - 24px)',
            },
            '& .MuiToolbar-regular': {
                paddingRight: '24px'
            }
        },
    });

    const classes = useStyles();

    return (
        <DialogProvider>
            <Show
                title={<Title/>}
                component={'div'}
                className={classes.root}
                {...props}
            >
                {props.children}
            </Show>
        </DialogProvider>
    )
}

export default CustomShow;
