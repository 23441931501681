import React from 'react';
import {
    ShowButton
} from '../../node_modules/react-admin';

const NamedShowButton = ({...props}) => {
    const {record, source} = props;
    const name = record ? record.name : '';
    return <ShowButton {...props} label={name} />
};

export default NamedShowButton;
