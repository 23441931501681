import {Grid} from '@material-ui/core';
import React, { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomLabeled from "../Components/CustomLabeled";
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

var sanitizeRestProps = function (_a) {
    var children = _a.children, className = _a.className, record = _a.record, resource = _a.resource, basePath = _a.basePath, version = _a.version, initialValues = _a.initialValues, translate = _a.translate, rest = __rest(_a, ["children", "className", "record", "resource", "basePath", "version", "initialValues", "translate"]);
    return rest;
};

const FieldsView = function(_a) {

    var basePath = _a.basePath,
        className = _a.className,
        children = _a.children,
        record = _a.record,
        resource = _a.resource,
        version = _a.version,
        columns = _a.columns,
        label = _a.label,
        rest = __rest(_a, ["basePath", "className", "children", "record", "resource", "version"]);

    const useStyles = makeStyles({
        root: {
            flexDirection: 'row',
            display: 'flex',
        },
    });

    const classes = useStyles();
    const breakpointClass = 'sm';

    const isSmall = useMediaQuery(theme => theme.breakpoints.down(breakpointClass));
    console.log(isSmall);

    return (
        record === undefined ? null : React.createElement(
            Grid,
            {key: version, container: true, spacing: 2 },
            Children.map(children, function (field) {
                return field && isValidElement(field) ? (
                    React.createElement(
                        "div", {
                            span: isSmall ? 12 : (field.props.span || (12 / columns)),
                            key: field.props.source,
                            className: classnames("ra-field ra-field-" + field.props.source, field.props.className)
                        },
                        field.props.addLabel ? (
                            React.createElement(
                                CustomLabeled, {
                                    record: record,
                                    resource: resource,
                                    basePath: basePath,
                                    label: field.props.label,
                                    source: field.props.source,
                                    disabled: false,
                                    key: field.props.source + '-label',
                                    className: isSmall ? '' : classnames(classes.root)
                                },
                                field)
                        ) : typeof field.type === 'string' ? (field) : (cloneElement(field, {
                            record: record,
                            resource: resource,
                            basePath: basePath,
                            key: field.props.source + '-label'
                        }))
                    )
                ) : null;
            }).map(child => {
                let childAttrs = {key: child.key, item: true, xs: 12};
                childAttrs[breakpointClass] = child.props.span;
                return child && isValidElement(child) ? (React.createElement(
                    Grid,
                    __assign(childAttrs, sanitizeRestProps(rest)),
                    [child]
                )) : null;
            })
        )
    );
};

FieldsView.propTypes = {
    children: PropTypes.node,
};

export default FieldsView;
