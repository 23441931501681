import React from "react";
import { Box, Typography, makeStyles} from '@material-ui/core';
import WriteEmailButton from '../Components/WriteEmailButton';
import {
    TextField,
    DateField,
    NumberField,
    ReferenceArrayField,
    SingleFieldList,
    SelectField,
    ReferenceField,
} from '../../node_modules/react-admin';
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomList from "../Components/CustomList";
import CustomSimpleList from "../Components/CustomSimpleList";
import {Languages} from "../Enums";
import { useTranslate } from "react-admin";

const DonorsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => <SelectField record={record} choices={Languages} source={"preferredLanguage"}/>}
        tertiaryText={record => ([<WriteEmailButton record={record}/>, <DateField source={"dateCreated"} record={record}/>])}
    />
};

const CampaignsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => (
            <ReferenceField basePath={"users"} record={record} source={"fundraiser"} reference={"users"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        tertiaryText={record => (<DateField record={record} source={"dateStart"}/>)}
    />
};

export const CustomerCareDashboard = () => {

    const translate = useTranslate();

    const useStyles = makeStyles({
        h1: {
            paddingLeft: '0.5em',
            color: '#585858',
        },
    });

    const classes = useStyles();

    return <Box>
        <Box mt={2} mb={1}>
            <Typography className={classes.h1} variant="h5" component="h1" children={translate("dashboards.customerCare.newDonors")}/>
        </Box>
        <Box mb={3}>
            <CustomList
                pagination={null}
                actions={false}
                filters={null}
                title={"Customer Care Dashboard"}
                basePath={"/donors"}
                resource={"donors"}
                hasList={true}
                hasCreate={false}
                filter={{
                    exists: {
                        userAssigned: false
                    }
                }}
                perPage={100}
                sort={{field: 'dateCreated', order: 'ASC'}}
                smallList={<DonorsSimpleList/>}
            >
                <CustomDatagrid>
                    <TextField source={"name"}/>
                    <ReferenceArrayField label="Email Addresses" source="emailAddresses" reference='email_addresses'>
                        <SingleFieldList linkType={false}>
                            <TextField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <SelectField source={"preferredLanguage"} choices={[
                        {id: 'de_DE', name: 'Deutsch'},
                        {id: 'en_us', name: 'Englisch'}
                    ]}/>
                    <NumberField source="donatedAmount" options={{style: 'currency', currency: 'EUR'}}/>
                    <DateField source="dateCreated" showTime={true}/>
                    <WriteEmailButton/>
                </CustomDatagrid>
            </CustomList>
        </Box>

        <Box mb={1}>
            <Typography className={classes.h1} variant="h5" component="h1" children={translate("dashboards.customerCare.newCampaigns")}/>
        </Box>
        <Box mb={1}>
            <CustomList
                pagination={null}
                actions={false}
                filters={null}
                basePath={"/campaigns"}
                title={" "}
                resource={"campaigns"}
                hasList={true}
                hasCreate={false}
                filter={{
                    exists: {
                        userAssigned: false
                    }
                }}
                sort={{field: 'dateCreated', order: 'ASC'}}
                perPage={100}
                smallList={<CampaignsSimpleList/>}
            >
                <CustomDatagrid>
                    <TextField source={"name"}/>
                    <ReferenceField source="fundraiser" reference="users" label="Fundraiser">
                        <TextField source="name"/>
                    </ReferenceField>
                    <NumberField source="goal" options={{style: 'currency', currency: 'EUR'}}/>
                    <DateField source="dateStart"/>
                    <DateField source="dateEnd"/>
                    <TextField source="url"/>
                </CustomDatagrid>
            </CustomList>
        </Box>
    </Box>
};
