"use strict";

import React, { useState, useEffect } from 'react';
import {
    Filter,
} from '../../node_modules/react-admin';

import {
    InputGuesser,
    Introspecter
} from '@api-platform/admin'

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

const SchemaBasedFilters = ({
   fields,
   readableFields,
   writableFields,
   schema,
   schemaAnalyzer,
   hasShow,
   hasEdit,
   ...rest
}) => {

    const actualFilters = [];

    const [filtersParameters, setFiltersParameters] = useState([]);

    useEffect(() => {
        if (schema) {
            schemaAnalyzer
                .getFiltersParametersFromSchema(schema)
                .then((parameters) => setFiltersParameters(parameters));
        }
    }, [schema, schemaAnalyzer]);

    if (!filtersParameters.length) {
        return null;
    }

    const guestFilters = filtersParameters.map(filter => React.createElement(InputGuesser, {
        key: filter.name,
        source: filter.name,
        alwaysOn: filter.isRequired
    }));

    if(rest.children)
    {
        if(rest.children instanceof Array) {
            rest.children.forEach(function (child) {
                actualFilters.push(child);
            });
        } else {
            actualFilters.push(rest.children);
        }
    }

    guestFilters.forEach(function (filter) {
        var hasFilter = false;
        actualFilters.forEach(function (actualFilter) {
            if(filter.props.source === actualFilter.props.source) {
                hasFilter = true;
            }
        });

        if(!hasFilter)
        {
            actualFilters.push(filter);
        }
    });

    return React.createElement(
        Filter,
        rest,
        actualFilters
    );
};

const SchemaBasedFiltersInspector = props => React.createElement(Introspecter, _extends({
    component: SchemaBasedFilters
}, props));

export default SchemaBasedFiltersInspector;
