import React from "react";
import { cloneElement } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
} from '../../node_modules/react-admin';
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";

const CustomListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const CustomList = props => {

    const useStyles = makeStyles({
        root: {
            '& .MuiTableCell-body': {
                whiteSpace: 'nowrap',
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
        },
    });

    const classes = useStyles();

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List
            filters={<SchemaBasedFiltersInspector variant="standard"/>}
            actions={<CustomListActions/>}
            perPage={25}
            sort={{ field: (props.sortBy ?? 'dateCreated'), order: 'DESC' }}
            hasShow={false}
            hasEdit={false}
            bulkActionButtons={false}
            className={classes.root}
            {...props}
        >
            {isSmall ? (props.smallList) : (props.children)}
        </List>
    )
}

export default CustomList;