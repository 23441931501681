import React from 'react';
import PropTypes from 'prop-types';
import Receipt from '@material-ui/icons/Receipt';
import { useDialog } from "./AlertDialogProvider";

import { connect } from 'react-redux';
import { showNotification, hideNotification, Button, translate } from 'react-admin';
import { Typography, List, ListItem, Box, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const defaultIcon = React.createElement(Receipt, null);
const ShowDonationSummaryRecipeButton = function (_a) {
    let label = _a.label === void 0 ? 'action.showDonationSummaryRecipe' : _a.label;
    let record = _a.record;
    let translate = _a.translate;
    let _d = _a.icon;
    let showNotification = _a.showNotification;
    let hideNotification = _a.hideNotification;
    let icon = _d === void 0 ? defaultIcon : _d;
    let [anchorEl, setAnchorEl] = React.useState(null);
    let [openDialog, closeDialog] = useDialog();

    let openDonationSummaryRecipe = function (year, preview, overwrite) {
        hideNotification();
        showNotification('resources.donors.creatingDonationSummaryRecipe', 'info', {
            key: 'test'
        });
        fetch(process.env.REACT_APP_API_URL + record['@id'] + '/show_summary_receipt/' + year + '/' + (preview ? '1' : '0') + '/' + (overwrite ? '1' : '0'), {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.status === 400) {
                    return response.json();
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.blob()
            })
            .then(blob => {
                if (!(blob instanceof Blob)) {
                    throw new Error(blob.error);
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Sammelspendenbescheinigung_" + year + ".pdf";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            })
            .catch(error => {
                hideNotification();
                showNotification(error.message, 'error', {
                    key: 'test'
                });
            });
    };

    let handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    }

    let handleClose = function (event) {
        setAnchorEl(null);

        let year = event.target.dataset.year || null;

        let onPreview = () => {
            openDonationSummaryRecipe(year, true, true);
            closeDialog();
        };
        let onFinal = () => {
            openDonationSummaryRecipe(year, false, true);
            closeDialog();
        };
        let onExisting = () => {
            openDonationSummaryRecipe(year, false, false);
            closeDialog();
        };
        let onCancel = () => {
            closeDialog();
        }

        if (year) {
            openDialog({
                children: (
                    <>
                        <DialogTitle id="alert-dialog-title">{"Sammelspendenbescheinigung"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <List>
                                    <ListItem>
                                        <Typography component={'span'} style={{fontWeight: 600}}>Anzeigen</Typography><Typography component={'span'}>&nbsp;-&nbsp;Zeigt die aktuelle Vorschau oder das aktuelle Original an.</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography component={'span'} style={{fontWeight: 600}}>Vorschau</Typography>&nbsp;-&nbsp;Erzeugt eine neue Vorschau.
                                    </ListItem>
                                    <ListItem>
                                        <Typography component={'span'} style={{fontWeight: 600}}>Neu</Typography>&nbsp;-&nbsp;Erstellt ein neues Original. Es muss vermerkt werden, dass das alte überschrieben wird.
                                    </ListItem>
                                </List>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"outlined"} color="primary" onClick={onCancel}>
                                <>Abbrechen</>
                            </Button>
                            <Button variant="outlined" color={"primary"} onClick={onExisting}>
                                <>Anzeigen</>
                            </Button>
                            <Button variant={"outlined"} color="primary" onClick={onPreview}>
                                <>Vorschau</>
                            </Button>
                            <Button variant={"outlined"} color="primary" onClick={onFinal}>
                                <>Neu</>
                            </Button>
                        </DialogActions>
                    </>
                ),
            });

            event.stopPropagation();
        }
    }

    let years = [];
    let yearLimit = (new Date()).getFullYear();
    for (let currentYear = 2017; currentYear <= yearLimit; currentYear++) {
        years.push(currentYear);
    }

    return (
        React.createElement(Box, {}, [
            React.createElement(Button,
                {
                    label: label,
                    onClick: handleClick,
                    "aria-controls": "simple-menu",
                    "aria-haspopup": true
                },
                icon
            ),
            React.createElement(Menu,
                {
                    id: 'simple-menu',
                    anchorEl: anchorEl,
                    keepMounted: true,
                    open: Boolean(anchorEl),
                    onClose: handleClose
                },
                years.map(function (item) {
                    return React.createElement(MenuItem, {
                        onClick: handleClose,
                        'data-year': item,
                    }, translate('resources.donors.year') + ' ' + item)
                })
            )
        ])
    );
};

ShowDonationSummaryRecipeButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default connect(null, {
    showNotification, hideNotification
})(translate(ShowDonationSummaryRecipeButton));
