// in src/authProvider.js
export default {
    // called when the user attempts to log in
    login: ({token}) => {
        localStorage.setItem('token', token);

        return fetch(new Request(process.env.REACT_APP_API_URL + '/api/users/me', {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }),
        }))
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

            return response.json();
        }).then((response) => {
            localStorage.setItem('user', JSON.stringify(response));
        }).catch(reason => {
            console.log(reason);
        });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({response}) => {
        if (response && response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
