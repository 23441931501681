import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

import { googleLogout } from '@react-oauth/google'

export const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();

    let onFailure = logoutFailure => {
        console.log(logoutFailure);
    };

    let onLogoutSuccess = logoutSuccess => {
        console.log(logoutSuccess);
        logout()
    }

    const handleClick = () => {
        googleLogout()
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Logout
        </MenuItem>
    );
});
