module.exports = {
    de: {
        enums: {
            languages: {
                de: 'Deutsch',
                en: 'Englisch',
            },
            payment_providers: {
                paypal: 'Paypal',
                stripe: 'Stripe',
                offline: 'Offline',
            },
            payment_methods: {
                sepa_debit: 'SEPA',
                giropay: 'Giropay',
                paypal: 'Paypal',
                card: 'Kreditkarte',
                offline: 'Offline',
            },
            donation_status: {
                paid: 'Erfolgreich',
                failed: 'Fehlgeschlagen',
                disputed: 'Angefochten',
                pending: 'In Bearbeitung',
                refunded: 'Zurückgezahlt'
            },
            subscription_status: {
                active: 'Aktiv',
                canceled: 'Gekündigt',
                failed: 'Fehlgeschlagen',
                paused: 'Pausiert',
                pending: 'In Bearbeitung'
            },
            subscription_periods: {
                monthly: 'Monatlich',
            },
            project_stages: {
                donation_assignment: '1 - Spendenzuteilung',
                planning: '2 - In Planung',
                in_execution: '3 - In Ausführung',
                completed: '4 - Abgeschlossen',
            },
            donor_source: {
                social_media: 'Social Media',
                tv: 'TV',
                podcast: 'Podcast',
                google: 'Google',
                website: 'Website',
                face_to_face: 'Face2Face',
            },
            donor_categories: {
                person: 'Privat-Person',
                account: 'Firma'
            }
        },
        login: {
            google: 'Mit Google anmelden',
        },
        dashboards: {
            customerCare: {
                newDonors: 'Neue Spender',
                newCampaigns: 'Neue Kampagnen'
            }
        },
        action: {
            writeEmail: 'E-Mail schreiben',
            showDonationSummaryRecipe: 'Sammelspendenbescheinigung',
            showDonationRecipe: 'Spendenbescheinigung',
            convertToDonation: 'Spende erstellen',
        },
        search: {
            quick: 'Schnellsuche (Namen)',
        },
        fields: {
            dateCreatedFieldSet: 'Erstellt',
            dateModifiedFieldSet: 'Zuletzt bearbeitet',
        },
        menu: {
            donorList: 'Spendenliste',
            emailTemplates: 'Email-Vorlagen',
            projectList: 'Projektliste'
        },
        resources: {
            transactions: {
                pluralName: 'Transaktionen',
                name: 'Transaktion',
                convertingToDonation: 'Erstelle Spende...',
                fields: {
                    amount: 'Betrag',
                    donation: 'Spende',
                    dateBooked: 'Buchungsdatum',
                    source: 'Quelle',
                    transactionNumber: 'Transactions-Nummer',
                    description: 'Beschreibung',
                    userAssigned: 'Zugewiesener Nutzer',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                }
            },
            donors: {
                year: 'Jahr',
                name: 'Spender',
                creatingDonationSummaryRecipe: 'Sammelspendenbescheinigung wird erstellt.',
                pluralName: 'Spender',
                fields: {
                    givenName: 'Vorname',
                    familyName: 'Nachname',
                    donatedAmount: 'Gespendet (€)',
                    raisedAmount: 'Gesammelt (€)',
                    preferredLanguage: 'Sprache',
                    emailAddresses: 'E-Mail-Adressen',
                    address: 'Adresse',
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                    addressFieldSet: 'Adresse',
                    streetAddress: 'Straße',
                    postalCode: 'Postleizzahl',
                    addressRegion: 'Bundesland',
                    addressCountry: 'Land',
                    addressLocality: 'Stadt',
                    externalId: 'Externe ID',
                    category: 'Kategorie',
                    source: 'Quelle',
                    isFromTeam: 'Vom Team?',
                },
            },
            donations: {
                name: 'Spende',
                pluralName: 'Spenden',
                creatingDonationRecipe: 'Spendenbescheinigung wird erstellt.',
                tabs: {
                    receipt: 'Spendenbescheinigung',
                    info: 'Infos',
                },
                fields: {
                    totalAmount: 'Betrag (€)',
                    status: 'Status',
                    paymentProvider: 'Zahlungsanbieter',
                    paymentMethod: 'Zahlungsart',
                    dateBooked: 'Buchungsdatum',
                    campaign: 'Kampagne',
                    donor: 'Spender',
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                    project: 'Projekt',
                    fee: 'Gebühr (€)',
                    netAmount: 'Netto-Betrag (€)',
                    externalId: 'Externe ID',
                    operatingCosts: 'Betriebskostenspende?',
                    notificationSent: 'E-Mail Benachrichtigung gesendet?',
                    subscription: 'Monatliche Spende',
                    receiptSent: 'Gesendet?',
                    receiptDateSent: 'Gesendet am',
                    receiptComment: 'Kommentar',
                    receiptArchiveId: 'Archivierungsnummer',
                    receiptArchiveProvider: 'Archivierungsplattform'
                }
            },
            campaigns: {
                name: 'Kampagne',
                pluralName: 'Kampagnen',
                fields: {
                    fundraiser: 'Fundraiser',
                    type: 'Typ',
                    raisedAmount: 'Gesammelt (€)',
                    numDonations: 'Anzahl Spenden',
                    dateStart: 'Start-Datum',
                    dateEnd: 'End-Datum',
                    goal: 'Ziel (€)',
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                    endNotificationSent: 'Abschluss-Bericht gesendet?'
                },
            },
            users: {
                name: 'Benutzer',
                pluralName: 'Benutzer',
                fields: {
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    externalId: 'Externe ID',
                    email: 'EMail-Adresse',
                    username: 'Benutzername',
                    fromWordpress: 'Von Webseite',
                    preferredLanguage: 'Sprache',
                    givenName: 'Vorname',
                    familyName: 'Nachname',
                },
            },
            email_addresses: {
                name: 'EMail-Adresse',
                pluralName: 'EMail-Adressen',
                fields: {
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                },
            },
            subscriptions: {
                name: 'Abonnement',
                pluralName: 'Abonnements',
                fields: {
                    monthlyAmount: 'Interval-Betrag (€)',
                    period: 'Interval',
                    donor: 'Spender',
                    totalAmount: 'Insgesamt (€)',
                    dateStart: 'Start-Datum',
                    paymentMethod: 'Zahlungsart',
                    paymentProvider: 'Zahlungsanbieter',
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                    dateEnded: 'Beendigungs-Datum',
                    notificationSent: 'Benachrichtigung gesendet?',
                    paymentToken: 'Zahlungs-Token',
                    externalSubscriberId: 'Externe Subscriber ID'
                },
            },
            projects: {
                name: 'Projekt',
                pluralName: 'Projekte',
                fields: {
                    userAssigned: 'Zugewiesener Nutzer',
                    description: 'Beschreibung',
                    dateModified: 'Zuletzt bearbeitet am',
                    dateCreated: 'Erstellt am',
                    userCreated: 'Erstellt von',
                    userModified: 'Zuletzt bearbeitet von',
                    external: 'Externe ID',
                    type: 'Typ',
                    cost: 'Kosten (€)',
                    totalAmount: 'Zugewiesene Brutto-Spenden (€)',
                    netAmount: 'Zugewiesene Netto-Spenden (€)',
                    fee: 'Spenden-Gebühren (€)',
                    ownAmount: 'Selbstbeteiligung (€)',
                    dateBooked: 'Überweisungsdatum',
                    openAmount: 'Offener Betrag (€)',
                    stage: 'Phase',
                    people: 'Menschen mit Wasser versorgt',
                    country: 'Land',
                    lastUpdate: 'Letztes Update',
                    numOfProject: 'Anzahl der Projekte'
                },
            },
            emails: {
                name: 'Email',
                pluralName: 'Emails',
                fields: {
                    templateName: 'Template',
                    causedBy: 'Ausgelöst durch',
                    dateCreated: 'Versendet am',
                    name: 'Betreff'
                }
            },
            external_identifiers: {
                name: 'Externe ID',
                pluralName: 'Externe IDs',
            },
        },
        localeSwitcher: {
            language: {
                label: 'Sprache auswählen',
                german: 'Deutsch',
                english: 'Englisch'
            }
        },
    },
    en: {
        enums: {
            languages: {
                de: 'German',
                en: 'English',
            },
            payment_providers: {
                paypal: 'Paypal',
                stripe: 'Stripe',
                offline: 'Offline',
            },
            payment_methods: {
                sepa_debit: 'SEPA',
                giropay: 'Giropay',
                paypal: 'Paypal',
                card: 'CreditCard',
                offline: 'Offline',
            },
            donation_status: {
                paid: 'Paid',
                failed: 'Failed',
                disputed: 'Disputed',
                pending: 'Pending',
                refunded: 'Refunded'
            },
            subscription_status: {
                active: 'Active',
                canceled: 'Canceled',
                failed: 'Failed',
                paused: 'Paused',
                pending: 'Pending'
            },
            subscription_periods: {
                monthly: 'Monthly',
            },
            project_stages: {
                donation_assignment: '1 - Donation Assignment',
                planning: '2 - Planning',
                in_execution: '3 - In execution',
                completed: '4 - Completed',
            },
        },
        donor_source: {
            social_media: 'Social Media',
            tv: 'TV',
            podcast: 'Podcast',
            google: 'Google',
            website: 'Website',
            face_to_face: 'Face2Face',
        },
        donor_categories: {
            person: 'Person',
            account: 'Account'
        },
        dashboards: {
            customerCare: {
                newDonors: 'New Donors',
                newCampaigns: 'New Campaigns'
            }
        },
        search: {
            quick: 'Quick Search (Name)',
        },
        login: {
            google: 'Login with Google',
        },
        action: {
            writeEmail: 'Write E-Mail',
            showDonationSummaryRecipe: 'Donation Summary Recipe',
            showDonationRecipe: 'Donation Recipe',
            convertToDonation: 'Create Donation'
        },
        fields: {
            dateCreatedFieldSet: 'Created at',
            dateModifiedFieldSet: 'Edited at',
        },
        menu: {
            donorList: 'Donation List',
            emailTemplates: 'Email Templates',
            projectList: 'Project List'
        },
        localeSwitcher: {
            language: {
                label: 'Select language',
                german: 'German',
                english: 'English'
            }
        },
        resources: {
            transactions: {
                pluralName: 'Transactions',
                name: 'Transaction',
                convertingToDonation: 'Creating Donation...',
            },
            emails: {
                name: 'Email ||| Emails',
                pluralName: 'Emails',
                fields: {
                    templateName: 'Template',
                    causedBy: 'Caused By',
                    dateCreated: 'Sent on',
                    name: 'Subject'
                }
            },
            donors: {
                year: 'Year',
                name: 'Donor',
                pluralName: 'Donors',
                forcedCaseName: 'Donor |||| Donors',
                creatingDonationSummaryRecipe: 'Loading donation summary recipe.',
                fields: {
                    givenName: 'First Name',
                    familyName: 'Last Name',
                    donatedAmount: 'Donated (€)',
                    raisedAmount: 'Raised (€)',
                    preferredLanguage: 'Language',
                    emailAddresses: 'E-Mail Addresses',
                    address: 'Address',
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Last modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by',
                    userModified: 'Modified by',
                    addressFieldSet: 'Address',
                    streetAddress: 'Street',
                    postalCode: 'Postal Code',
                    addressRegion: 'State',
                    addressCountry: 'Country',
                    addressLocality: 'City',
                    externalId: 'External Id',
                    category: 'Category',
                    source: 'Source',
                    isFromTeam: 'From Team?',
                },
            },
            donations: {
                name: 'Donation',
                pluralName: 'Donations',
                fields: {
                    totalAmount: 'Amount (€)',
                    status: 'Status',
                    paymentProvider: 'Payment Provider',
                    paymentMethod: 'Payment Method',
                    dateBooked: 'Booking Date',
                    campaign: 'Campaign',
                    donor: 'Donor',
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    project: 'Project',
                    fee: 'Fee (€)',
                    netAmount: 'Net-Amount (€)',
                    externalId: 'External ID',
                }
            },
            campaigns: {
                name: 'Campaign',
                pluralName: 'Campaigns',
                fields: {
                    fundraiser: 'Fundraiser',
                    type: 'Type',
                    raisedAmount: 'Raised (€)',
                    numDonations: '# Donors',
                    dateStart: 'Start Date',
                    dateEnd: 'End Date',
                    goal: 'Goal (€)',
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    externalId: 'External ID',
                    endNotificationSent: 'Final Report sent?'
                }
            },
            users: {
                name: 'User',
                pluralName: 'User',
                fields: {
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    externalId: 'External ID',
                    email: 'E-Mail Address',
                    username: 'Username',
                    fromWordpress: 'From Website',
                    preferredLanguage: 'Language',
                    givenName: 'First Name',
                    familyName: 'Last Name',
                },
            },
            email_addresses: {
                name: 'E-Mail Address',
                pluralName: 'E-Mail Addresses',
                fields: {
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    externalId: 'External ID',
                }
            },
            subscriptions: {
                name: 'Abonnement',
                pluralName: 'Abonnements',
                fields: {
                    monthlyAmount: 'Interval Amount (€)',
                    period: 'Interval',
                    donor: 'Donor',
                    totalAmount: 'Total Amount (€)',
                    dateStart: 'Start Date',
                    paymentMethod: 'Payment Method',
                    paymentProvider: 'Payment Provider',
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    externalId: 'External ID',
                    dateEnded: 'EndD Date',
                    notificationSent: 'Notification sent?',
                    paymentToken: 'Payment Token',
                    externalSubscriberId: 'External Subscriber ID'
                }
            },
            projects: {
                name: 'Project',
                pluralName: 'Projects',
                fields: {
                    userAssigned: 'Assigned User',
                    description: 'Description',
                    dateModified: 'Date modified',
                    dateCreated: 'Created',
                    userCreated: 'Created by ',
                    userModified: 'Modified By',
                    externalId: 'External ID',
                    type: 'Type',
                    cost: 'Cost (€)',
                    totalAmount: 'Assigned Amount (€)',
                    netAmount: 'Assigned Net Amount (€)',
                    fee: 'Fees (€)',
                    ownAmount: 'Own Amount (€)',
                    dateBooked: 'Booking Date',
                    openAmount: 'Open Amount (€)',
                    stage: 'Stage',
                    people: 'People',
                    country: 'Country',
                    lastUpdate: 'Last Update',
                    numOfProject: 'Number of projects'
                }
            },
            external_identifiers: {
                name: 'External ID',
                pluralName: 'External IDs',
            },
        }
    }
};
