export const Languages = [
    {
        id: 'de_DE',
        name: 'enums.languages.de'
    },
    {
        id: 'en_US',
        name: 'enums.languages.en'
    }
];

export const DonorCategories = [
    {
        id: 'person',
        name: 'enums.donor_categories.person'
    },
    {
        id: 'account',
        name: 'enums.donor_categories.account'
    }
];

export const DonorSource = [
    {
        id: 'social_media',
        name: 'enums.donor_source.social_media'
    },
    {
        id: 'tv',
        name: 'enums.donor_source.tv'
    },
    {
        id: 'podcast',
        name: 'enums.donor_source.podcast'
    },
    {
        id: 'google',
        name: 'enums.donor_source.google'
    },
    {
        id: 'website',
        name: 'enums.donor_source.website'
    },
    {
        id: 'face_to_face',
        name: 'enums.donor_source.face_to_face'
    },
];

export const DonationStatus = [
    {
        id: 'paid',
        name: 'enums.donation_status.paid',
    },
    {
        id: 'pending',
        name: 'enums.donation_status.pending',
    },
    {
        id: 'failed',
        name: 'enums.donation_status.failed',
    },
    {
        id: 'disputed',
        name: 'enums.donation_status.disputed',
    },
    {
        id: 'refunded',
        name: 'enums.donation_status.refunded',
    },
];

export const SubscriptionStatus = [
    {
        id: 'failed',
        name: 'enums.subscription_status.failed',
    },
    {
        id: 'active',
        name: 'enums.subscription_status.active',
    },
    {
        id: 'canceled',
        name: 'enums.subscription_status.canceled',
    },
    {
        id: 'pending',
        name: 'enums.subscription_status.pending',
    },
    {
        id: 'paused',
        name: 'enums.subscription_status.paused',
    },
];

export const PaymentProviders = [
    {
        id: 'paypal',
        name: 'enums.payment_providers.paypal'
    },
    {
        id: 'stripe',
        name: 'enums.payment_providers.stripe'
    },
    {
        id: 'offline',
        name: 'enums.payment_providers.offline',
    }
];

export const PaymentMethods = [
    {
        id: 'card',
        name: 'enums.payment_methods.card'
    },
    {
        id: 'sepa_debit',
        name: 'enums.payment_methods.sepa_debit'
    },
    {
        id: 'giropay',
        name: 'enums.payment_methods.giropay'
    },
    {
        id: 'paypal',
        name: 'enums.payment_methods.paypal'
    },
    {
        id: 'offline',
        name: 'enums.payment_methods.offline',
    }
];

export const SubscriptionPeriods = [
    {
        id: 'monthly',
        name: 'enums.subscription_periods.monthly'
    }
];

export const ProjectStages = [
    {
        id: 'donation_assignment',
        name: 'enums.project_stages.donation_assignment'
    },
    {
        id: 'planning',
        name: 'enums.project_stages.planning'
    },
    {
        id: 'in_execution',
        name: 'enums.project_stages.in_execution'
    },
    {
        id: 'completed',
        name: 'enums.project_stages.completed'
    },
]
