import React, {cloneElement} from "react";
import {
    TextField,
    BooleanField,
    TextInput,
    BooleanInput,
    Edit,
    Create,
    useListContext,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps
} from '../../node_modules/react-admin';
import { Box } from '@material-ui/core';
import SimpleColumnForm from "../views/SimpleColumnForm";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import FieldsView from "../views/FieldsView";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomList from '../Components/CustomList'
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";

const EmailsListFilters = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <TextInput label="search.quick" source="name" alwaysOn/>
    </SchemaBasedFiltersInspector>
);

const EmailsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField source={"name"} record={record}/>)}
        secondaryText={record => ([<Box component={"span"}>Opt Out: </Box>, <BooleanField record={record} source={"optOut"}/>])}
    />
};

export const EmailAddressList = props => (
    <CustomList {...props}
        filters={<EmailsListFilters variant={"standard"}/>}
        smallList={<EmailsSimpleList/>}
        actions={<EmailAddressListActions/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <BooleanField source={"optOut"} />
            <BooleanField source={"invalid"} />
        </CustomDatagrid>
    </CustomList>
);


export const EmailAddressShow = props => (
    <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField disabled source={"name"}/>
                <BooleanField disabled source={"invalid"} />
                <BooleanField source={"optOut"} />
            </FieldsView>
        </SimpleColumnLayout>
    </CustomShow>
);

export const EmailAddressEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput disabled source={"name"}/>
            <BooleanInput disabled source={"invalid"} />
            <BooleanInput source={"optOut"} />
        </SimpleColumnForm>
    </Edit>
);

export const EmailAddressCreate = props => (
    <Create {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput source={"name"}/>
            <BooleanInput disabled source={"invalid"} />
            <BooleanInput source={"optOut"} />
        </SimpleColumnForm>
    </Create>
);

const EmailAddressListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    );
};
