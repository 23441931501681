import React, {cloneElement} from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Card } from '@material-ui/core';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

var SimpleColumnLayout = function (_a) {
    var className = _a.className,
        children = _a.children;

    var fieldsView = children[0] ?? children;
    var subpanelsView = children[1] ?? null;

    return (
        React.createElement(
            Box,
            { className: className},
            [
                React.createElement(
                    Card,
                    {
                        key: 0
                    },
                    React.createElement(
                        CardContent,
                        {},
                        cloneElement(fieldsView, __assign({}, _a, fieldsView.props))
                    )
                ),
                subpanelsView && cloneElement(subpanelsView, __assign({
                    key: 1
                }, _a, subpanelsView.props))
            ]
        )
    );
};

SimpleColumnLayout.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    record: PropTypes.object,
    resource: PropTypes.string,
    version: PropTypes.number,
    columns: PropTypes.number
};

export default SimpleColumnLayout;
