import React from 'react';
import PropTypes from 'prop-types';
import Receipt from '@material-ui/icons/Receipt';
import {
    Button,
} from '../../node_modules/react-admin';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';

var ConvertToDonationButton = function (_a) {
    let label = _a.label === void 0 ? 'action.convertToDonation' : _a.label;
    let record = _a.record;
    let _d = _a.icon;
    let icon = _d === void 0 ? defaultIcon : _d;

    const { showNotification } = _a;

    let convertDonation = function (e) {
        showNotification('resources.transactions.convertingToDonation', 'info');

        fetch(process.env.REACT_APP_API_URL + record['@id'] + '/convert', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.error(error);
        });

        return e.stopPropagation();
    };

    return (
        React.createElement(Button,
            {
                label: label,
                onClick: convertDonation
            },
            icon
        ));
};

var defaultIcon = React.createElement(Receipt, null);

ConvertToDonationButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default connect(null, {
    showNotification,
})(ConvertToDonationButton);
