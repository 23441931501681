import React from "react";

import {
    DateField,
    NumberField,
    TextField,
    ReferenceField,
    TopToolbar,
    EditButton,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    TextInput
} from '../../node_modules/react-admin';
import { Box } from '@material-ui/core';
import CustomList from '../Components/CustomList'
import ConvertToDonationButton from "../Components/ConvertToDonationButton";

import SimpleColumnLayout from "../views/SimpleColumnLayout";
import FieldsView from "../views/FieldsView";
import FieldSet from "../views/FieldSet";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomLabeled from "../Components/CustomLabeled";

const TransactionsShowAction = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ConvertToDonationButton record={data}/>
    </TopToolbar>
);

const TransactionsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField source={"name"} record={record}/>)}
        secondaryText={record => (<NumberField record={record} source="amount" options={{ style: 'currency', currency: 'EUR' }} />)}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
};

const TransactionsListFilter = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <ReferenceInput source="donation" reference="donations" filterToQuery={searchText => ({ name: searchText})}>
            <AutocompleteInput optionText={"name"} optionValue={"id"}/>
        </ReferenceInput>
        <DateInput source={"dateBooked"}/>
        <TextInput source={"transactionNumber"}/>
    </SchemaBasedFiltersInspector>
);

export const TransactionsList = props => (
    <CustomList {...props}
        sortBy={"dateBooked"}
        smallList={<TransactionsSimpleList/>}
        filters={<TransactionsListFilter variant={"standard"}/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
            <ReferenceField source="donation" reference="donations" link={"show"}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateBooked"/>
            <TextField source={"transactionNumber"}/>
            <TextField source={"source"}/>
            <TextField source={"description"}/>
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

export const TransactionsShow = props => (
    <CustomShow {...props}
        actions={<TransactionsShowAction/>}
    >
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <NumberField source="amount" options={{ style: 'currency', currency: 'EUR' }} />
                <ReferenceField source="donation" reference="donations" link={"show"}>
                    <TextField source="name"/>
                </ReferenceField>

                <DateField source="dateBooked"/>
                <TextField source={"source"}/>

                <TextField source={"transactionNumber"}/>
                <Box/>
                <TextField source={"description"} span={12}/>

                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </FieldSet>
            </FieldsView>
        </SimpleColumnLayout>
    </CustomShow>
);
