import React from "react";
import {
    Labeled,
} from '../../node_modules/react-admin';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const CustomLabeled = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles({
        root: {
            '& .MuiFormLabel-root': {
                minWidth: '12rem',
                textAlign: 'right',
            },
            '& .MuiInputLabel-shrink': {
                transform: 'translate(0, 4px) scale(0.8)'
            },
            '& > div': {
                padding: 0
            },
        }
    });

    let classes = useStyles();
    return (
        <Labeled
            {...props}
            className={(isSmall ? '' : classnames(classes.root)) + (props.className ? (' ' + props.className) : '')}
        >
            {props.children}
        </Labeled>
    )
}

export default CustomLabeled;