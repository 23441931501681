import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    SimpleList,
} from '../../node_modules/react-admin';

const CustomSimpleList = props => {

    const useStyles = makeStyles({
        root: {
            '& .MuiListItem-root': {
                borderBottom: '1px solid rgba(0,0,0,.05)',
            }
        },
    });

    const classes = useStyles();

    return (
        <SimpleList
            linkType="show"
            className={classes.root}
            {...props}
        />
    )
}

export default CustomSimpleList;