import React from 'react';
import PropTypes from 'prop-types';
import Receipt from '@material-ui/icons/Receipt';
import { connect } from 'react-redux';
import { showNotification, hideNotification, Button, translate } from 'react-admin';
import { List, ListItem, Typography, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import {useDialog} from "./AlertDialogProvider";

const defaultIcon = React.createElement(Receipt, null);
const ShowDonationRecipeButton = function (_a) {
    let label = _a.label === void 0 ? 'action.showDonationRecipe' : _a.label;
    let record = _a.record;
    let _d = _a.icon;
    let icon = _d === void 0 ? defaultIcon : _d;

    let [openDialog, closeDialog] = useDialog();

    let showNotification = _a.showNotification;
    let hideNotification = _a.hideNotification;

    let showDialog = (e) => {

        let onShowExisting = () => {
            openDonationReceipt(true, false);
        }

        let onPreview = () => {
            openDonationReceipt(true, true);
            closeDialog();
        };

        let onFinal = () => {
            openDonationReceipt(false, true);
            closeDialog();
        };

        let onCancel = () => {
            closeDialog();
        }

        openDialog({
            children: (
                <>
                    <DialogTitle id="alert-dialog-title">{"Spendenbescheinigung"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <List>
                                <ListItem>
                                    <Typography component={'span'} style={{fontWeight: 600}}>Anzeigen</Typography><Typography component={'span'}>&nbsp;-&nbsp;Zeigt die aktuelle Vorschau oder das aktuelle Original an.</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography component={'span'} style={{fontWeight: 600}}>Vorschau</Typography>&nbsp;-&nbsp;Erzeugt eine neue Vorschau.
                                </ListItem>
                                <ListItem>
                                    <Typography component={'span'} style={{fontWeight: 600}}>Neu</Typography>&nbsp;-&nbsp;Erstellt ein neues Original. Es muss vermerkt werden, dass das alte überschrieben wird.
                                </ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={onCancel}>
                            <>Abbrechen</>
                        </Button>
                        {record.receiptSent &&
                            <Button variant="outlined" color={"primary"} onClick={onShowExisting}>
                                <>Anzeigen</>
                            </Button>
                        }
                        <Button variant="outlined" color="primary" onClick={onPreview}>
                            <>Vorschau</>
                        </Button>
                        <Button variant="outlined" color={"primary"} onClick={onFinal}>
                            <>Neu</>
                        </Button>
                    </DialogActions>
                </>
            ),
        });

        return e.stopPropagation();
    }

    let openDonationReceipt = function (preview, overwrite) {

        showNotification('resources.donations.creatingDonationRecipe', 'info');

        fetch(process.env.REACT_APP_API_URL + record['@id'] + '/show_receipt/' + (preview ? '1' : '0') + '/' + (overwrite ? '1' : '0'), {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
        })
            .then(response => {
                if (response.status === 400) {
                    return response.json();
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.blob()
            })
            .then(blob => {
                if (!(blob instanceof Blob)) {
                    throw new Error(blob.error);
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Spendenbescheinigung.pdf";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }).catch(error => {
                hideNotification();
                showNotification(error.message, 'error', {
                    key: 'test'
                });
            });
    };

    return (
        React.createElement(Button,
            {
                label: label,
                onClick: showDialog
            },
            icon
        ));
};

ShowDonationRecipeButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default connect(null, {
    showNotification, hideNotification
})(translate(ShowDonationRecipeButton));
