import { Box } from '@material-ui/core';
import React, { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CustomLabeled from "../Components/CustomLabeled";
import { useMediaQuery } from '@material-ui/core';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

var sanitizeRestProps = function (_a) {
    var children = _a.children, className = _a.className, record = _a.record, resource = _a.resource, basePath = _a.basePath, version = _a.version, initialValues = _a.initialValues, translate = _a.translate, rest = __rest(_a, ["children", "className", "record", "resource", "basePath", "version", "initialValues", "translate"]);
    return rest;
};

const FieldSet = function(_a) {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    var basePath = _a.basePath,
        className = _a.className,
        children = _a.children,
        record = _a.record,
        resource = _a.resource,
        version = _a.version,
        columns = _a.columns,
        variant = _a.variant,
        rest = __rest(_a, ["basePath", "className", "children", "record", "resource", "version"]);

    let label = null;
    if(children[0].type === CustomLabeled) {
       label = children[0];
       let newChildren = [];
       for(let x = 1; x < children.length; x++) {
           newChildren.push(children[x]);
       }
       children = newChildren;
    }

    const useStyles = makeStyles({
        root: isSmall ? {} : {
            flexDirection: label ? 'row' : 'column',
            display: 'flex',
            '& .ra-field': {
                paddingRight: '1rem',
            },
            paddingTop: '8px',
            paddingBottom: '4px'
        },
        subBox: {

        }
    });
    const classes = useStyles();

    children = Children.map(children, function (field) {
        return field && isValidElement(field) ? (
            React.createElement("div", {
                    key: field.props.source,
                    className: classnames("ra-field ra-field-" + field.props.source, field.props.className)
                },typeof field.type === 'string' ? (field) : (
                    cloneElement(field, {
                        record: record,
                        variant: variant,
                        resource: resource,
                        basePath: basePath,
                    })
                )
            )
        ) : null;
    });

    return (
        React.createElement(
            Box,
            __assign({
                className: classnames(className, classes.root),
                key: version
            }, sanitizeRestProps(rest)),
            label ? [label,
                React.createElement(Box, {
                        key: version + 'x',
                        className: classnames(classes.subBox)
                    },
                    children
                )
            ] : children
        )
    );
};

FieldSet.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    record: PropTypes.object,
    resource: PropTypes.string,
    version: PropTypes.number,
};

export default FieldSet;
