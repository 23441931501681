import React from "react";

import {
    DateField,
    NumberField,
    TextField,
    ReferenceField,
    SelectInput,
    BooleanField,
    TextInput,
    Edit,
    DateInput,
    EditButton,
    NumberInput,
    BooleanInput,
    SelectField,
    ReferenceInput,
    AutocompleteInput,
    Tab,
    FormTab,
    TopToolbar,
} from '../../node_modules/react-admin';
import CustomList from '../Components/CustomList'
import { Box } from '@material-ui/core';

import ShowDonationRecipeButton from '../Components/ShowDonationRecipeButton';
import {DonationStatus, PaymentMethods, PaymentProviders} from "../Enums";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import FieldsView from "../views/FieldsView";
import FieldSet from "../views/FieldSet";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomLabeled from "../Components/CustomLabeled";
import CustomTabbedShowLayout from "../views/CustomTabbedShowLayout";
import CustomTabbedForm from "../views/CustomTabbedForm";
import CustomTabbedFormView from "../views/CustomTabbedFormView";

const DonationsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (
            <ReferenceField basePath={"donors"} record={record} source={"donor"} reference={"donors"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        secondaryText={record => ([
            <NumberField record={record} source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField choices={DonationStatus} record={record} source={"status"}/>
        ])}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
};

const DonationsListFilters = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <SelectInput source={"status"} choices={DonationStatus}/>
        <SelectInput source={"paymentProvider"} choices={PaymentProviders}/>
        <SelectInput source={"paymentMethod"} choices={PaymentMethods}/>
        <ReferenceInput source="project" reference="projects" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
            <AutocompleteInput optionText={"name"} optionValue={"id"}/>
        </ReferenceInput>
    </SchemaBasedFiltersInspector>
);

export const DonationsList = props => (
    <CustomList {...props}
        sortBy={"dateBooked"}
        smallList={<DonationsSimpleList/>}
        filters={<DonationsListFilters variant={"standard"}/>}
    >
        <CustomDatagrid>
            <ReferenceField source="donor" reference="donors" link={"show"}>
                <TextField source="name"/>
            </ReferenceField>
            <NumberField source="totalAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <SelectField source={"status"} choices={DonationStatus} />
            <ReferenceField source="campaign" reference="campaigns" link={"show"}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateBooked"/>
            <SelectField source={"paymentMethod"} choices={PaymentMethods} />
            <BooleanField source={"operatingCosts"}/>
            <ReferenceField source="project" reference="projects" link={"show"}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

const DonationShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ShowDonationRecipeButton basePath={basePath} record={data}/>
    </TopToolbar>
);

export const DonationsShow = props => (
    <CustomShow {...props} actions={<DonationShowActions/>}>
        <CustomTabbedShowLayout>
            <Tab label={"resources.donations.tabs.info"}>
                <SimpleColumnLayout>
                    <FieldsView columns={2}>
                        <NumberField source="totalAmount" options={{ style: 'currency', currency: 'EUR' }} />
                        <NumberField source="netAmount" options={{ style: 'currency', currency: 'EUR' }} />
                        <NumberField source="fee" options={{ style: 'currency', currency: 'EUR' }} />
                        <ReferenceField source="donor" reference="donors">
                            <TextField source="name"/>
                        </ReferenceField>
                        <DateField source="dateBooked"/>
                        <SelectField source={"status"} choices={DonationStatus} />
                        <SelectField source={"paymentProvider"} choices={PaymentProviders} />
                        <SelectField source={"paymentMethod"} choices={PaymentMethods} />
                        <ReferenceField source="campaign" reference="campaigns">
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField source="project" reference="projects">
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField source="subscription" reference="subscriptions">
                            <TextField source="name"/>
                        </ReferenceField>
                        <Box/>
                        <TextField source={"transactionId"}/>
                        <BooleanField source={"operatingCosts"}/>
                        <TextField source={"description"} span={12}/>
                        <ReferenceField source={"userAssigned"} reference={"users"}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source={"externalId"} />
                        <BooleanField source={"notificationSent"} />
                        <Box/>
                        <FieldSet>
                            <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                            <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                            <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        </FieldSet>
                        <FieldSet>
                            <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                            <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                            <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        </FieldSet>
                    </FieldsView>
                </SimpleColumnLayout>
            </Tab>
            <Tab label={"resources.donations.tabs.receipt"}>
                <SimpleColumnLayout>
                    <FieldsView columns={2}>
                        <BooleanField source="receiptSent"/>
                        <DateField source="receiptDateSent" showTime={true}/>

                        <TextField source="receiptArchiveProvider"/>
                        <TextField source="receiptArchiveId" span={12}/>
                        <TextField source="receiptComment" span={12}/>
                    </FieldsView>
                </SimpleColumnLayout>
            </Tab>
        </CustomTabbedShowLayout>
    </CustomShow>
);

export const DonationsEdit = props => (
    <Edit {...props}>
        <CustomTabbedForm variant={"outlined"} redirect="show">
            <FormTab label={"resources.donations.tabs.info"}>
                <CustomTabbedFormView>
                    <NumberInput disabled source="totalAmount"/>
                    <NumberInput disabled source="netAmount"/>
                    <NumberInput disabled source="fee"/>
                    <ReferenceInput disabled source="donor" reference="donors" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                        <AutocompleteInput optionText={"name"} optionValue={"id"}/>
                    </ReferenceInput>
                    <DateInput disabled source="dateBooked"/>
                    <SelectInput disabled source={"status"} choices={DonationStatus} />
                    <SelectInput disabled source={"paymentProvider"} choices={PaymentProviders} />
                    <SelectInput source={"paymentMethod"} choices={PaymentMethods} />
                    <ReferenceInput source="campaign" reference="campaigns" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                        <AutocompleteInput optionText={"name"} optionValue={"id"}/>
                    </ReferenceInput>
                    <ReferenceInput source="project" reference="projects" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                        <AutocompleteInput optionText={"name"} optionValue={"id"}/>
                    </ReferenceInput>
                    <ReferenceInput disabled source="subscription" reference="subscriptions">
                        <AutocompleteInput optionText={"name"} optionValue={"id"}/>
                    </ReferenceInput>
                    <Box/>
                    <TextInput disabled source={"transactionId"}/>
                    <BooleanInput source={"operatingCosts"}/>
                    <TextInput multiline source={"description"} span={12}/>
                    <ReferenceInput source={"userAssigned"} reference={"users"} filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                        <AutocompleteInput optionText={"name"} optionValue={"id"}/>
                    </ReferenceInput>
                    <TextInput disabled source={"externalId"} />
                </CustomTabbedFormView>
            </FormTab>
            <FormTab label={"resources.donations.tabs.receipt"}>
                <CustomTabbedFormView>
                    <BooleanInput source="receiptSent"/>
                    <DateInput source="receiptDateSent" showTime={true}/>

                    <TextInput disabled source="receiptArchiveProvider"/>
                    <TextInput disabled source="receiptArchiveId" span={12}/>

                    <TextInput source="receiptComment" span={12}/>
                </CustomTabbedFormView>
            </FormTab>
        </CustomTabbedForm>
    </Edit>
);
