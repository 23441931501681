import React from "react";
import {
    Datagrid,
} from '../../node_modules/react-admin';
import { makeStyles } from '@material-ui/core/styles';

const CustomDatagrid = props => {

    const useStyles = makeStyles({
        root: {
            overflowX: 'scroll',
            display: 'block'
        }
    });

    let classes = useStyles();

    return (
        <Datagrid
            {...props}
            rowClick={'show'} size={"medium"}
            className={classes.root}
        >
            {props.children}
        </Datagrid>
    )
}

export default CustomDatagrid;