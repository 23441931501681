import React from "react";
import {
    DateField,
    NumberField,
    TextField,
    ReferenceField,
    DateInput,
    TextInput,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    UrlField,
    ReferenceInput,
    AutocompleteInput,
    Edit,
    SelectField,
    Pagination,
    BooleanField,
    Labeled
} from '../../node_modules/react-admin';
import { Box } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import NamedShowButton from "../Components/NamedShowButton";
import FieldsView from "../views/FieldsView";
import {DonationStatus, PaymentMethods} from "../Enums";
import FieldSet from "../views/FieldSet";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import Subpanel from "../views/Subpanel";
import SubpanelsView from "../views/SubpanelsView";
import SimpleColumnForm from "../views/SimpleColumnForm";
import CustomList from '../Components/CustomList'
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomLabeled from "../Components/CustomLabeled";

const CampaignsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<TextField record={record} source={"name"}/>)}
        secondaryText={record => (
            <ReferenceField basePath={"users"} record={record} source={"fundraiser"} reference={"users"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        tertiaryText={record => (<DateField record={record} source={"dateStart"}/>)}
    />
};

const DonationsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (
            <ReferenceField basePath={"donors"} record={record} source={"donor"} reference={"donors"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        secondaryText={record => ([
            <NumberField record={record} source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField choices={DonationStatus} record={record} source={"status"}/>
        ])}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
}

const CampaignsListFilters = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <TextInput label="search.quick" source="name" alwaysOn variant="outlined"/>
    </SchemaBasedFiltersInspector>
);

export const CampaignsList = props => (
    <CustomList {...props}
          sortBy={"dateStart"}
          smallList={<CampaignsSimpleList/>}
          filters={<CampaignsListFilters variant="standard"/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <ReferenceField source="fundraiser" reference="users">
                <TextField source="name"/>
            </ReferenceField>
            <NumberField source="raisedAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField source="numDonations"/>
            <DateField source="dateStart"/>
            <DateField source="dateEnd"/>
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

export const CampaignsShow = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <CustomShow {...props} component={'div'}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <UrlField source="url" span={12}/>
                <DateField source="dateStart" showTime={true}/>
                <DateField source="dateEnd" showTime={true}/>
                <NumberField source="goal" options={{style: 'currency', currency: 'EUR'}}/>
                <NumberField source="raisedAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <NumberField source={"numDonations"}/>
                <ReferenceField source="fundraiser" reference="users">
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source={"endNotificationSent"}/>
                <TextField source={"description"} span={12}/>
                <ReferenceField source={"userAssigned"} reference={"users"}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source={"externalId"}/>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
            </FieldsView>
            <SubpanelsView>
                <Subpanel label={"resources.donations.pluralName"}>
                    <ReferenceManyField sort={{field: 'dateBooked', order: 'DESC'}} pagination={<Pagination/>}
                                        perPage={5} reference="donations" target="campaign" addLabel={false}>
                        {isSmall ? (
                            <DonationsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <NamedShowButton/>
                                <NumberField source={"totalAmount"} options={{style: 'currency', currency: 'EUR'}}/>
                                <ReferenceField source="donor" reference="donors">
                                    <TextField source="name"/>
                                </ReferenceField>
                                <SelectField source={"status"} choices={DonationStatus}/>
                                <SelectField source={"paymentMethod"} choices={PaymentMethods}/>
                                <DateField source={"dateBooked"} showTime={true}/>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
            </SubpanelsView>
        </SimpleColumnLayout>
    </CustomShow>
};

export const CampaignsEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <TextInput type={"url"} disabled source="url" span={12}/>
            <DateInput source="dateStart"/>
            <DateInput source="dateEnd"/>
            <NumberInput source="goal"/>
            <NumberInput source="raisedAmount" disabled />
            <NumberInput source={"numDonations"} disabled />
            <ReferenceInput source="fundraiser" reference="users" disabled filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <BooleanInput source={"endNotificationSent"} disabled/>
            <TextInput multiline source={"description"} span={12}/>
            <ReferenceInput source={"userAssigned"} reference={"users"} filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
        </SimpleColumnForm>
    </Edit>
);
