import React from "react";
import {
    DateField,
    TextField,
    ReferenceField,
    Labeled,
} from '../../node_modules/react-admin';

import FieldSet from "../views/FieldSet";
import FieldsView from "../views/FieldsView";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import CustomShow from "../Components/CustomShow";
import CustomLabeled from "../Components/CustomLabeled";

export const ExternalIdentifiersShow = (props) => {
    return <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <TextField source="name"/>
                <TextField source="provider"/>
                <ReferenceField source={"donor"} reference={"donors"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source={"userAssigned"} reference={"users"}>
                    <TextField source="name"/>
                </ReferenceField>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
            </FieldsView>
        </SimpleColumnLayout>
    </CustomShow>
};
