import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslate, useLocale, useSetLocale } from "react-admin";

const LocaleSwitcher = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const setLocale = useSetLocale();

    return (
        <div>
            <div>{translate("localeSwitcher.language.label")}</div>
            <Button disabled={locale === "de"} onClick={() => {
                localStorage.setItem('selectedLocale', 'de');
                setLocale("de")
            }}>
                {translate("localeSwitcher.language.german")}
            </Button>
            <Button disabled={locale === "en"} onClick={() => {
                localStorage.setItem('selectedLocale', 'en');
                setLocale("en")
            }}>
                {translate("localeSwitcher.language.english")}
            </Button>
        </div>
    );
};

export default LocaleSwitcher;
