import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Redirect, Route } from "react-router-dom";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser
} from "@api-platform/admin";

import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";

import { DonorsList, DonorsShow, DonorsEdit } from './modules/donors';
import { DonationsList, DonationsShow, DonationsEdit } from './modules/donations';
import { CampaignsList, CampaignsShow, CampaignsEdit } from './modules/campaigns';
import { TransactionsList, TransactionsShow } from './modules/transactions';
import { SubscriptionsList, SubscriptionsCreate, SubscriptionsShow, SubscriptionsEdit } from './modules/subscriptions';
import { ProjectsList, ProjectsShow, ProjectsEdit, ProjectsCreate } from './modules/projects';
import {EmailsList, EmailsShow} from './modules/emails';
import { ExternalIdentifiersShow } from './modules/externalIdentifiers';
import { UsersList, UsersShow, UsersEdit } from './modules/users';
import {EmailAddressEdit, EmailAddressList, EmailAddressShow, EmailAddressCreate} from './modules/emailAddresses';
import { CustomerCareDashboard } from './views/CustomerCareDashboard';
import { CustomLayout } from "./views/CustomLayout";
import { LogoutButton } from "./Components/LogoutButton"
import myRoutes from "./routes";
import LoginView from "./views/LoginView"
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';
import * as domainMessages from './i18n';
import {MyTheme} from './theme';

import UserIcon from '@material-ui/icons/People';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import EmailIcon from '@material-ui/icons/Email';

const entrypoint = process.env.REACT_APP_API_URL + '/api';

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers({ Authorization: `Bearer ${window.localStorage.getItem("token")}` }),
});

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers({ Authorization: `Bearer ${window.localStorage.getItem("token")}` }) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    window.localStorage.removeItem("token");
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return window.localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const messages = {
    en: { ...englishMessages, ...domainMessages.en },
    de: { ...germanMessages, ...domainMessages.de}
};

messages.de.ra.action.unselect = 'Abwählen';
messages.de.ra.page.loading = 'Seite wird geladen';
messages.de.ra.message.details = 'Details';
messages.de.ra.notification.logged_out = 'Du wurdest abgemeldet';

messages.en.ra.notification.logged_out = 'You have been logged out';

let locale = resolveBrowserLocale();
let user = localStorage.getItem('user');
if(user)
{
    user = JSON.parse(user);
    locale = ((user.locale || locale).substr(0,2)) || 'de';
}

locale = localStorage.getItem('selectedLocale') || locale;

const i18nProvider = polyglotI18nProvider(function (l) {
    return messages[l];
}, locale);

export default () => (
  <GoogleOAuthProvider nonce={Math.floor(Math.random() * 1000000) + ""} clientId={"91332439015-6be1d2sc722onuvhki1ggjk5vvaikduf.apps.googleusercontent.com"}><HydraAdmin
      theme={MyTheme}
      loginPage={LoginView}
      i18nProvider={i18nProvider}
      customRoutes={myRoutes}
      layout={CustomLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      entrypoint={entrypoint}
      dashboard={CustomerCareDashboard}
      logout={LogoutButton}
  >
      <ResourceGuesser name="donors" icon={UserIcon} list={DonorsList} show={DonorsShow} edit={DonorsEdit}/>
      <ResourceGuesser name="donations" icon={LocalAtmIcon} list={DonationsList} show={DonationsShow} edit={DonationsEdit}/>
      <ResourceGuesser name="campaigns" icon={EventNoteIcon} list={CampaignsList} show={CampaignsShow} edit={CampaignsEdit}/>
      <ResourceGuesser name="subscriptions" icon={AllInclusiveIcon} create={SubscriptionsCreate} list={SubscriptionsList} show={SubscriptionsShow} edit={SubscriptionsEdit}/>
      <ResourceGuesser name="projects" icon={LocalDrinkIcon} list={ProjectsList} show={ProjectsShow} edit={ProjectsEdit} create={ProjectsCreate}/>
      <ResourceGuesser name="users" icon={AccountCircleIcon} list={UsersList} show={UsersShow} edit={UsersEdit}/>
      <ResourceGuesser name="emails" icon={EmailIcon} list={EmailsList} edit={null} create={null} show={EmailsShow}/>
      <ResourceGuesser name="email_addresses" icon={AlternateEmailIcon} list={EmailAddressList} show={EmailAddressShow} edit={EmailAddressEdit} create={EmailAddressCreate}/>
      <ResourceGuesser name="external_identifiers" icon={FingerprintIcon} list={null} edit={null} create={null} show={ExternalIdentifiersShow}/>
      <ResourceGuesser name="transactions" list={TransactionsList} show={TransactionsShow} edit={null} create={null} />
  </HydraAdmin></GoogleOAuthProvider >
);
