import React, {
    cloneElement,
} from 'react';

import {
    TabbedShowLayout,
    Tab
} from 'react-admin'

import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import SubpanelsView from "./SubpanelsView";

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

let CustomTabbedShowLayout = function (_a) {
    let className = _a.className,
        children = _a.children;

    let tabs = [];
    let subpanelsView = null;

    const isTabletOrMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    let style = {
        'content': {
            paddingLeft: '8px',
            paddingRight: '8px'
        },
    };

    if(!isTabletOrMobile) {
        style = {
            'content': {
                paddingLeft: '0',
                paddingRight: '0'
            },
        };
    }

    const useStyles = makeStyles(style);
    const classes = useStyles();

    children.forEach((child) => {
        if(!child || !child.type) {
            return;
        }

        if(child.type.prototype === Tab.prototype) {
           tabs.push(child);
        } else if(child.type.prototype === SubpanelsView.prototype) {
            subpanelsView = child;
        }
    });

    return (
        React.createElement(
            Box,
            {
                className: className
            },
            [
                React.createElement(
                    TabbedShowLayout,
                    {
                        ..._a,
                        classes: classes,
                    },
                    tabs
                ),
                subpanelsView && cloneElement(subpanelsView, __assign({
                    key: 1
                }, _a, subpanelsView.props))
            ]
        )
    );
};

CustomTabbedShowLayout.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    record: PropTypes.object,
    resource: PropTypes.string,
    version: PropTypes.number,
    columns: PropTypes.number
};

export default CustomTabbedShowLayout;
