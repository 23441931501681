import React from "react";

import {
    DateField,
    TextField,
    NumberField,
    Edit,
    Create,
    ReferenceField,
    SelectField,
    BooleanField,
    Pagination,
    SelectInput,
    TextInput,
    DateInput,
    NumberInput,
    BooleanInput,
    AutocompleteInput,
    ReferenceInput,
    Labeled,
    ReferenceManyField,
} from '../../node_modules/react-admin';

import NamedShowButton from "../Components/NamedShowButton";

import {
    SubscriptionStatus,
    PaymentMethods,
    SubscriptionPeriods,
    DonationStatus, PaymentProviders
} from "../Enums";
import { useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/core';
import FieldSet from "../views/FieldSet";
import FieldsView from "../views/FieldsView";
import SimpleColumnLayout from "../views/SimpleColumnLayout";
import SubpanelsView from "../views/SubpanelsView";
import Subpanel from "../views/Subpanel";
import CustomList from '../Components/CustomList'
import SimpleColumnForm from "../views/SimpleColumnForm";
import CustomDatagrid from "../Components/CustomDatagrid";
import CustomShow from "../Components/CustomShow";
import CustomSimpleList from "../Components/CustomSimpleList";
import SchemaBasedFiltersInspector from "../views/SchemaBasedFilters";
import CustomLabeled from "../Components/CustomLabeled";

const SubscriptionsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (
            <ReferenceField basePath={"donors"} record={record} source={"donor"} reference={"donors"} link={false}>
                <TextField source={"name"}/>
            </ReferenceField>
        )}
        secondaryText={record => ([
            <NumberField record={record} source={"monthlyAmount"} options={{ style: 'currency', currency: 'EUR' }}/>,
            <Box component={"span"}> - </Box>,
            <SelectField choices={SubscriptionStatus} record={record} source={"status"}/>
        ])}
        tertiaryText={record => (<DateField record={record} source={"dateStart"}/>)}
    />
};

const DonationsSimpleList = () => {
    return <CustomSimpleList
        primaryText={record => (<NumberField record={record} source={"totalAmount"} options={{ style: 'currency', currency: 'EUR' }}/>)}
        secondaryText={record => (<SelectField choices={DonationStatus} record={record} source={"status"}/>)}
        tertiaryText={record => (<DateField record={record} source={"dateBooked"}/>)}
    />
}

const SubscriptionsListFilter = (props) => (
    <SchemaBasedFiltersInspector {...props}>
        <SelectInput source={"status"} choices={SubscriptionStatus}/>
        <SelectInput source={"paymentProvider"} choices={PaymentProviders}/>
        <SelectInput source={"paymentMethod"} choices={PaymentMethods}/>
    </SchemaBasedFiltersInspector>
);

export const SubscriptionsList = props => (
    <CustomList {...props}
        sortBy={"dateStart"}
        smallList={<SubscriptionsSimpleList/>}
        filters={<SubscriptionsListFilter/>}
    >
        <CustomDatagrid>
            <TextField source={"name"}/>
            <NumberField source="monthlyAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <SelectField source="period" choices={SubscriptionPeriods} />
            <ReferenceField source="donor" reference="donors">
                <TextField source="name"/>
            </ReferenceField>
            <NumberField source="totalAmount" options={{ style: 'currency', currency: 'EUR' }} />
            <DateField source="dateStart"/>
            <SelectField source={"status"} choices={SubscriptionStatus} />
            <SelectField source={"paymentMethod"} choices={PaymentMethods} />
            <DateField source="dateModified" showTime={true} />
            <DateField source="dateCreated" showTime={true} />
        </CustomDatagrid>
    </CustomList>
);

export const SubscriptionsShow = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <CustomShow {...props}>
        <SimpleColumnLayout>
            <FieldsView columns={2}>
                <ReferenceField source="donor" reference="donors">
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="totalAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <NumberField source="monthlyAmount" options={{style: 'currency', currency: 'EUR'}}/>
                <SelectField source="period" choices={SubscriptionPeriods}/>
                <SelectField source={"status"} choices={SubscriptionStatus}/>
                <SelectField source={"paymentMethod"} choices={PaymentMethods}/>
                <DateField source="dateStart"/>
                <DateField source={"dateEnded"}/>
                <BooleanField source={"notificationSent"}/>
                <TextField source={"description"} span={12}/>
                <ReferenceField source={"userAssigned"} reference={"users"}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source={"externalId"}/>
                <FieldSet>
                    <CustomLabeled label={"fields.dateCreatedFieldSet"} margin={""}/>
                    <DateField source={"dateCreated"} label={"fields.dateCreatedFieldSet"} showTime={true} addLabel={false}/>
                    <ReferenceField source={"userCreated"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <FieldSet>
                    <CustomLabeled label={"fields.dateModifiedFieldSet"} margin={""}/>
                    <DateField source={"dateModified"} addLabel={false} showTime={true}/>
                    <ReferenceField source={"userModified"} reference={"users"} addLabel={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </FieldSet>
                <TextField source={"paymentToken"}/>
                <TextField source={"externalSubscriberId"}/>
            </FieldsView>
            <SubpanelsView>
                <Subpanel label={"resources.donations.pluralName"}>
                    <ReferenceManyField sort={{field: 'dateBooked', order: 'DESC'}} pagination={<Pagination/>}
                                        perPage={5} reference="donations" target="subscription" addLabel={false}>
                        {isSmall ? (
                            <DonationsSimpleList/>
                        ) : (
                            <CustomDatagrid>
                                <NumberField source={"totalAmount"} options={{style: 'currency', currency: 'EUR'}}/>
                                <SelectField source={"status"} choices={DonationStatus}/>
                                <SelectField source={"paymentMethod"} choices={PaymentMethods}/>
                                <DateField source={"dateBooked"} showTime={true}/>
                            </CustomDatagrid>
                        )}
                    </ReferenceManyField>
                </Subpanel>
            </SubpanelsView>
        </SimpleColumnLayout>
    </CustomShow>
};

export const SubscriptionsEdit = props => (
    <Edit {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <ReferenceInput disabled source="donor" reference="donors" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <NumberInput disabled source="totalAmount"/>
            <NumberInput disabled source="monthlyAmount"/>
            <SelectInput disabled source="period" choices={SubscriptionPeriods} />
            <SelectInput disabled source={"status"} choices={SubscriptionStatus} />
            <SelectInput disabled source={"paymentMethod"} choices={PaymentMethods} />
            <DateInput disabled source="dateStart"/>
            <DateInput disabled source={"dateEnded"}/>
            <BooleanInput disabled source={"notificationSent"}/>
            <TextInput multiline source={"description"} span={12}/>
            <ReferenceInput source={"userAssigned"} reference={"users"} filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <TextInput disabled source={"externalId"} />
            <TextInput disabled source={"paymentToken"}/>
            <TextInput disabled source={"externalSubscriberId"}/>
        </SimpleColumnForm>
    </Edit>
);

export const SubscriptionsCreate = props => (
    <Create {...props}>
        <SimpleColumnForm variant={"outlined"} redirect="show">
            <ReferenceInput source="donor" reference="donors" filterToQuery={searchText => ({ name: searchText, fromWordpress: 0 })}>
                <AutocompleteInput optionText={"name"} optionValue={"id"}/>
            </ReferenceInput>
            <NumberInput source="monthlyAmount"/>
            <SelectInput source="period" choices={SubscriptionPeriods} />
            <SelectInput source={"status"} choices={SubscriptionStatus} />
            <SelectInput source={"paymentProvider"} choices={PaymentProviders} />
            <SelectInput source={"paymentMethod"} choices={PaymentMethods} />
            <DateInput source="dateStart"/>
        </SimpleColumnForm>
    </Create>
);
